import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../../utils/style';
import IcomoonIcon from '../../shared/IcomoonIcon';

const El = styled('button')`

   -webkit-appearance: none;
   display:inline-block;
   color: ${color.white};
   font-size: 1rem;
   float: right;
   span {
     margin-right: 10px;
   } 
   
   @media (max-width: ${breakpoints.md}) {
    display:inline-block;
    color: ${color.white};
    font-size: 1rem;
   }
   @media (max-width: ${breakpoints.sm}) {
      font-size: 14px;
   }
   @media (max-width: ${breakpoints.xs}) {
      &.black-button {
        padding: 14px 15px;
      }
   }
`;

const OpemSubsLink = ( props ) => {
  return (
    <El className="button black-button" aria-label="Open Subcategories" onClick={props.handlerFunction}>
      <span dangerouslySetInnerHTML={{ __html: props.title }}/>
      <IcomoonIcon icon="arrow-down-simple" color={color.trueBlack} size={16} />
    </El>
  );
};

export default OpemSubsLink;

OpemSubsLink.propTypes = {
  handlerFunction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};