import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/shared/layoutParallax"
import SEO from "../../components/SEO/SEO"
import styled from "@emotion/styled"
import {
  featuredWrapper,
  noFeaturedWrapper,
  color,
  wrapper,
  breakpoints,
  ContentStyles,
  ContentStylesExt,
  wrapper1200,
} from "../../utils/style"
import Img from "gatsby-image"
import Breadcrumbs from "../../components/shared/Breadcrumbs"
import Subcategories from "../../components/Content/Blog/Subcategories"
import GetWindowWidth from "../../components/shared/GetWindowWidth"

const FeaturedWrapper = styled("div")`
  ${featuredWrapper}
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  .top-wrap {
    position: absolute;
    top: 25%;
    left: 32px;
    right: 32px;
  }
  h1 {
    margin: 0 0 20px;
    font-weight: 800;
    font-size: 64px;
    line-height: 124.6%;
  }
  .subtitle {
    font-size: 16px;
    line-height: 24px;
    white-space: pre-wrap;
  }
  .gatsby-image-wrapper {
    border-radius: 24px;
    max-height: 448px;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background: ${color.transparentBlack};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      border-radius: 24px;
    }
  }
  img {
    border-radius: 24px;
  }
  @media (max-width: ${breakpoints.md}) {
    .gatsby-image-wrapper {
      max-height: 100%;
    }
    h1 {
      font-size: 26px;
      line-height: 32px;
      margin: 0 0 16px;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .top-wrap {
      top: 30%;
      left: 16px;
      right: 16px;
    }
    h1 {
      font-size: 22px;
      line-height: 30px;
      margin: 0;
    }
  }
`

const NoFeaturedWrapper = styled("div")`
  ${noFeaturedWrapper}
  h1 {
    font-size: 50px;
    margin: 0 0 -50px;
  }

  @media (max-width: ${breakpoints.sm}) {
    h1 {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    h1 {
      font-size: 26px;
    }
  }
  @media (max-width: ${breakpoints.xxs}) {
    h1 {
      font-size: 20px;
    }
  }
`

const WrapperDesktop = styled("div")`
  ${wrapper1200}
`

const ContentWrapper = styled("div")`
  ${wrapper}
  ${ContentStyles}
  ${ContentStylesExt}
`

const PostContentWrapper = styled("div")`
  margin: 20px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .a-center {
    flex-basis: 100%;
    padding: 15px 0;
    min-height: 60px;
  }

  a {
    flex-basis: 49%;
    position: relative;
    margin: 10px 0;
    background: ${color.transparentWhite1};
    padding: 24px 16px;
    border-radius: 16px;
    .gatsby-image-wrapper {
      border-radius: 10px;
    }
    .image-wrap {
      position: relative;
    }

    .text-part-2 {
      padding: 16px 0;
    }

    .h5 {
      display: block;
      font-size: 22px;
      line-height: 25px;
      margin: 0;
      height: 50px;
      overflow: hidden;
    }

    .button {
      width: 100%;
    }

    .excerpt {
      display: block;
      font-size: 16px;
      line-height: 18px;
      line-height: 22px;
      max-height: 110px;
      overflow: hidden;
    }

    &:hover,
    &:active {
      text-decoration: none;
      color: ${color.white};
      border: none;
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    a {
      flex-basis: 49%;
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    a {
      flex-basis: 100%;
      margin-bottom: 25px;
      .h5 {
        height: auto;
      }
    }
  }
`

const BottomBanner = styled("div")`
  background: linear-gradient(
    128.46deg,
    rgba(169, 0, 0, 0.4) 25.23%,
    rgba(120, 0, 0, 0.1) 88.32%
  );
  padding: 32px 0;
  text-align: center;
  span {
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    color: ${color.gold};
  }
`

const createGroups = (pages, pageLength) =>
  pages
    .map((edge, index) => {
      return index % pageLength === 0
        ? pages.slice(index, index + pageLength)
        : null
    })
    .filter(item => item)

let nextPageIndex = 1
const Category = ({ data, pageContext }) => {
  const windowWidth = GetWindowWidth()
  const isDesktop = windowWidth > 600
  const currentCategory = data.allWordpressCategory.edges[0].node
  const metaTitle =
    currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_title
      ? currentCategory.yoast_meta.yoast_wpseo_title
      : currentCategory.title + "- " + process.env.OWNER
  const metaDesc =
    currentCategory.yoast_meta &&
    currentCategory.yoast_meta.yoast_wpseo_metadesc
      ? currentCategory.yoast_meta.yoast_wpseo_metadesc
      : ""
  const breadcrumbs = pageContext.breadcrumbs
  const page_title = pageContext.page_title
  const page_subtitle = currentCategory.acf.category_description_top
  const bottomBanner = currentCategory.acf.bottom_banner

  const pageLength = pageContext.pageLength
  const groups = createGroups(data.allWordpressPost.edges, pageLength)
  const [items, setItems] = useState(groups[0].concat([]))
  const submenuLinkTitle =
    currentCategory.wordpress_id === 51 ? "All blog" : currentCategory.name

  const loadMore = (e, index) => {
    nextPageIndex = nextPageIndex + 1
    setItems(items.concat(groups[index]))
  }

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentCategory.path.replace("category/", "")}
        key="seo"
        breadcrumbs={breadcrumbs}
      />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
        {currentCategory.acf &&
        currentCategory.acf.category_image &&
        currentCategory.acf.category_image.localFile &&
        isDesktop ? (
          <FeaturedWrapper>
            <Img
              fluid={
                currentCategory.acf.category_image.localFile.childImageSharp
                  .fluid
              }
              alt={
                currentCategory.acf.category_image.alt_text
                  ? currentCategory.acf.category_image.alt_text
                  : currentCategory.name
              }
            />
            <div className="top-wrap">
              <h1 className="h1 rich-design">
                <span
                  dangerouslySetInnerHTML={{ __html: currentCategory.name }}
                />
              </h1>
              {page_subtitle && (
                <span
                  className="subtitle"
                  dangerouslySetInnerHTML={{ __html: page_subtitle }}
                />
              )}
            </div>
          </FeaturedWrapper>
        ) : (
          <NoFeaturedWrapper>
            <h1 className="h1 rich-design">
              <span
                dangerouslySetInnerHTML={{ __html: currentCategory.name }}
              />
            </h1>
            {page_subtitle && (
              <span
                className="subtitle"
                dangerouslySetInnerHTML={{ __html: page_subtitle }}
              />
            )}
          </NoFeaturedWrapper>
        )}
        {(currentCategory.wordpress_id === 51 ||
          currentCategory.path.indexOf("blog")) > 0 && (
          <Subcategories title={submenuLinkTitle} />
        )}
        <PostContentWrapper>
          {items.map(({ node }) => (
            <Link className="item" key={node.slug} to={"/" + node.slug + "/"}>
              <div className="image-wrap">
                {node.featured_media &&
                node.featured_media.localFile &&
                node.featured_media.localFile.childImageSharp &&
                node.featured_media.localFile.childImageSharp.fluid ? (
                  <Img
                    fluid={node.featured_media.localFile.childImageSharp.fluid}
                    alt={
                      node.featured_media.alt_text
                        ? node.featured_media.alt_text
                        : node.title
                    }
                  />
                ) : (
                  <Img
                    fluid={data.placeholder.childImageSharp.fluid}
                    alt={node.title}
                  />
                )}
              </div>
              <div className="text-part-2">
                <span className="h5">{node.title}</span>
                {/*<div className="excerpt" dangerouslySetInnerHTML={{ __html: node.excerpt }}/>*/}
              </div>
              <span className="button transparent-button">Read Article</span>
            </Link>
          ))}
          {groups.length > 1 && nextPageIndex <= groups.length - 1 && (
            <div className="a-center">
              <button
                className="button transparent-button"
                onClick={e => loadMore(e, nextPageIndex)}
              >
                <span>โหลดมากขึ้น...</span>
              </button>
            </div>
          )}
        </PostContentWrapper>
        {currentCategory.description && (
          <ContentWrapper>
            <div
              dangerouslySetInnerHTML={{ __html: currentCategory.description }}
            />
          </ContentWrapper>
        )}
      </WrapperDesktop>
      {bottomBanner && (
        <BottomBanner>
          <WrapperDesktop>
            <span dangerouslySetInnerHTML={{ __html: bottomBanner }} />
          </WrapperDesktop>
        </BottomBanner>
      )}
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    allWordpressCategory(filter: { wordpress_id: { eq: $wordpress_id } }) {
      edges {
        node {
          id
          name
          slug
          description
          path
          wordpress_id
          link
          acf {
            category_description_top
            bottom_banner
            category_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1128) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
        }
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      filter: {
        categories: { elemMatch: { wordpress_id: { eq: $wordpress_id } } }
      }
    ) {
      edges {
        node {
          title
          excerpt
          slug
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
